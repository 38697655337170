import axios from 'axios';
import React, { useState, useCallback } from 'react';
import isEmail from 'validator/lib/isEmail';
import './SubscribeForm.scss';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);

  const inputChangeHandler = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (!email || !isEmail(email)) {
        setResponse({
          type: 'error',
          data: 'Please enter a valid email address.',
        });
        return;
      }

      setResponse(null);

      axios({
        url: process.env.GATSBY_SUBSCRIBE_URL,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ email }),
        method: 'POST',
      })
        .then(() => {
          setResponse({ type: 'success' });
        })
        .catch((err) => {
          setResponse({ type: 'error', data: err.toString() });
        });
    },
    [email],
  );

  return (
    <div className="inputBoxLayout">
      <form onSubmit={submitHandler} className="inputBox">
        <p className="label">Subscribe to our newsletter</p>
        <div className="input-layout">
          <input
            className="input"
            id="email"
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={inputChangeHandler}
          />
          <button type="submit" className="subscribe-btn">
            Subscribe
          </button>
        </div>
      </form>
      {response?.type === 'error' && (
        <div
          className="action-method"
          style={{ margin: '8px 0', color: 'red' }}>
          {response.data}
        </div>
      )}
      {response?.type === 'success' && (
        <div
          className="action-method"
          style={{ margin: '8px 0', color: 'green' }}>
          Success!
        </div>
      )}
    </div>
  );
};

export default SubscribeForm;

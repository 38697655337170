/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';
// import useClickOutside from '../../hooks/useClickOutside';
import './Navbar.scss';
import GetStartedCta from '../../Reusables/GetStartedCta';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  // const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(true);
  const [goingUp, setGoingUp] = useState(true);
  const prevScrollY = useRef(0);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (
        window.pageYOffset > 100 &&
        prevScrollY.current < currentScrollY &&
        goingUp
      ) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }
      prevScrollY.current = currentScrollY;
      if (headerRef.current) {
        headerRef.current.style.background =
          currentScrollY < 100 ? 'transparent' : '#fff';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  useEffect(() => {
    const body = document.querySelector('.body-wrapper');
    if (isNavOpen) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }, [isNavOpen]);

  const renderNavContent = () => (
    <div className={`nav container ${isNavOpen ? 'mobile' : 'animations'}`}>
      <div className="nav-left">
        <div className="nav-item nav-logo hidden-mobile">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <img className="hidden-mobile" src="./img/back-by-yc.svg" alt="" />
      </div>
      <div className="nav-center">
        {/* <div
          className="nav-item nav-dropdown"
          onClick={() => setIsDownloadDropdownOpen(!isDownloadDropdownOpen)}>
          <div className="wrap">
            Download
            <svg
              className={`chevron ${isDownloadDropdownOpen ? 'active' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L5 5L9 1"
                stroke="#0B0F27"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="dropdown-wrapper">
            <div
              className={`dropdown ${
                isDownloadDropdownOpen ? 'dropdown-open' : ''
              }`}>
              <a
                href="https://aerotime.com/downloads/app"
                target="_blank"
                className="dropdown-item"
                onClick={() => {
                  setIsNavOpen(false);
                  Segment.track(SEGMENT_ACTIONS.DOWNLOAD, {
                    From: SEGMENT_FROM.NAVBAR,
                  });
                }}>
                Desktop - MacOS
              </a>
            </div>
          </div>
        </div> */}
        <a
          href="https://aerotime.com/security"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer">
          Security
        </a>
        <a
          href="https://newsletter.aerotime.com/"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer">
          Newsletter
        </a>
        <a
          href="https://www.ycombinator.com/companies/aerotime/jobs"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer">
          Jobs
        </a>
      </div>
      <div className="nav-right hidden-mobile">
        {/* <a
          href="https://app.aerotime.com/?utm_source=website&utm_medium=navbar"
          className="nav-item secondary-btn"
          target="_blank"
          rel="noopener noreferrer">
          Login
        </a> */}
        <GetStartedCta />
      </div>
    </div>
  );

  return (
    <>
      <nav className={`header ${goingUp ? '' : 'nav-hidden'} `} ref={headerRef}>
        <div className={`mobile-nav ${isNavOpen ? 'isNavOpen' : ''}`}>
          <div className="nav-item nav-logo">
            <img src={logo} alt="" />
          </div>
          <GetStartedCta />
          {/* <span
            className={`menu-btn ${isNavOpen ? 'open' : ''}`}
           > */}
          <div
            className={`hamburger ${isNavOpen ? 'open' : ''}`}
            onClick={() => {
              setIsNavOpen(!isNavOpen);
            }}>
            <span></span>
          </div>
          {/* </span> */}
        </div>
        {renderNavContent()}
      </nav>
    </>
  );
};

export default Navbar;

import { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { Segment } from '../utils/segment';
import { SEGMENT_ACTIONS, SEGMENT_FROM } from '../utils/SegmentConstants';

function useRequestAccess(From = SEGMENT_FROM.HERO, inputRef) {
  const [noWaitList, setNoWaitList] = useState(false);
  useEffect(() => {
    const urlParam = new URLSearchParams(window.location.search);
    const myParam = urlParam?.get('noWaitlist');
    setNoWaitList(myParam === '1');
  }, []);

  const track = () => {
    Segment.track(
      noWaitList ? SEGMENT_ACTIONS.GET_STARTED : SEGMENT_ACTIONS.JOIN_WAIT_LIST,
      {
        From,
      },
    );
  };

  const link = noWaitList
    ? 'https://app.aerotime.com/?utm_source=website&utm_medium=get_started'
    : 'https://aerotime.typeform.com/waitlist';

  const open = () => {
    let url = link;
    if (inputRef && inputRef.current) {
      const email = inputRef.current.value;
      if (email && isEmail(email)) {
        url = `${link}#email=${email}`;
        inputRef.current.value = '';
      }
    }
    window.open(url, '_blank');
    track();
  };

  return {
    link,
    cta: noWaitList ? 'Get Started' : 'Request access',
    track,
    open,
  };
}

export default useRequestAccess;

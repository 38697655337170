export const Segment = {
  identify(userId, properties) {
    window.analytics.identify(userId, properties);
  },
  track(event, properties) {
    window.analytics.track(event, properties);
  },
  reset() {
    window.analytics.reset();
  },
  page(event, properties) {
    window.analytics.page(event, properties);
  },
  group(groupId, properties) {
    window.analytics.group(groupId, properties);
  },
};

import * as React from 'react';
import PropTypes from 'prop-types';
import 'normalize.css';

import '../styles/master.scss';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const Layout = ({ children }) => (
  <div className="body-wrapper">
    <Navbar />
    <main>{children}</main>

    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

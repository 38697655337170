import React from 'react';

import SubscribeForm from '../SubscribeForm/SubscribeForm';

import logo from '../../images/logo.svg';
import linkedin from '../../images/linkedin-icon.svg';
import twitter from '../../images/twitter-icon.svg';
import instagram from '../../images/instagram-icon.svg';
import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer-wrap container">
      <div className="col flex flex-col">
        <img loading="lazy" className="logo" src={logo} alt="" />
        <a
          style={{ marginBottom: '6px' }}
          href="mailto:hello@aerotime.com"
          className="link">
          hello@aerotime.com
        </a>
        <a
          style={{ marginBottom: '2px' }}
          href="tel:+14154187559"
          className="link">
          +1 (415) 418 - 7559
        </a>
        <p style={{ marginBottom: '16px' }}>San Francisco, CA</p>
      </div>
      <div className="links-container">
        <div className="company-links col">
          <p className="link-title">About</p>
          <div className="link-container">
            <a
              href="https://aerotime.com/security"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Security
            </a>
          </div>
          <div className="link-container">
            <a
              href="https://www.ycombinator.com/companies/aerotime/jobs"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Jobs
            </a>
          </div>
          <div className="link-container">
            <a
              href="http://help.aerotime.com/"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Support
            </a>
          </div>
        </div>
        <div className="company-links col">
          <p className="link-title">Socials</p>
          <div className="link-container">
            <img loading="lazy" className="icon" src={twitter} alt="" />
            <a
              href="https://twitter.com/AerotimeHQ"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Twitter
            </a>
          </div>
          <div className="link-container">
            <img loading="lazy" className="icon" src={linkedin} alt="" />
            <a
              href="https://www.linkedin.com/company/aerotime"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              LinkedIn
            </a>
          </div>
          <div className="link-container">
            <img loading="lazy" className="icon" src={instagram} alt="" />
            <a
              href="https://www.instagram.com/aerotimehq/"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Instagram
            </a>
          </div>
        </div>
      </div>

      <SubscribeForm />
    </div>
    <div className="footer-section-layer">
      <div className="footer-section container">
        <div className="footer-section-wrap">
          <div className="trademark">
            &copy; {new Date().getFullYear()} All rights reserved.
          </div>
          <div className="left">
            <a
              href="/privacy-policy/"
              className="link privacy-link"
              target="_blank"
              rel="noopener noreferrer">
              Privacy policy
            </a>
            <a
              href="/terms-of-use/"
              className="link"
              target="_blank"
              rel="noopener noreferrer">
              Terms of use
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

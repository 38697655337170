export const SEGMENT_ACTIONS = {
  GET_STARTED: 'Get started',
  LOGIN: 'Login',
  DOWNLOAD: 'Mac App Downloaded',
  JOIN_WAIT_LIST: 'Join Wait List',
  WEB_INVITE: 'Web Invite',
};

export const SEGMENT_FROM = {
  TOP_PANEL: 'Top Panel',
  CTA: 'CTA',
  FOOTER: 'Footer',
  HERO: 'Hero',
  INFO: 'Info',
  NAVBAR: 'Navbar',
  TASKS: 'Tasks',
  YEAR_IN_REVIEW: 'Year In Review',
  EARLY_ACCESS_BANNER: 'Early Access Banner',
  INVITE_MODAL: 'Invite Modal',
};

import React from 'react';
import useRequestAccess from '../../hooks/useRequestAccess';
import './getStartedCta.scss';

function GetStartedCta() {
  const { link, cta, track } = useRequestAccess();
  return (
    <a
      href={link}
      className="GetStartedCta"
      target="_blank"
      rel="noopener noreferrer"
      onClick={track}>
      {cta}
    </a>
  );
}

export default GetStartedCta;
